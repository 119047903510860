export const TOGGLE_EDIT_ACCOUNT_MODAL = 'TOGGLE_EDIT_ACCOUNT_MODAL'
export const TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL =
  'TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL'

export const toggleEditAccountModal = (show) => ({
  type: TOGGLE_EDIT_ACCOUNT_MODAL,
  show,
})
export const toggleEditAccountContactModal = (show) => ({
  type: TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL,
  show,
})
