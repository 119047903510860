import {
  TOGGLE_EDIT_ACCOUNT_MODAL,
  TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL,
} from '@actions/ui/accountPage'

const initialEditAccountModalState = { show: false }
const initialEditAccountContactModalState = { show: false }

export const editAccountModal = (
  state = initialEditAccountModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}

export const editAccountContactModal = (
  state = initialEditAccountContactModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}
