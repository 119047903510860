import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageBody } from '@res/styledComponents/index'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import Panel from '@components/common/Panel'
import {
  OrderStatus,
  MenuItemUpdates,
  ChefDirectory,
  ChefScheduleUpdates,
  VendorClientMap,
} from '@containers/chefNetwork'

const ChefNetworkPage = (props) => {
  const { userId, headquarters, loadHeadquarters, hqId } = props
  const [page, setPage] = useState('orderStatus')

  useEffect(() => {
    onLoadHeadquarters()
  }, [userId])

  const onLoadHeadquarters = async () => {
    await loadHeadquarters(userId)
  }

  const renderSideBar = () => {
    return (
      <Sidebar heading="Chef Network">
        <SidebarListItem
          isSelected={'orderStatus' === page}
          onClick={() => setPage('orderStatus')}
        >
          Chef Order Status
        </SidebarListItem>
        <SidebarListItem
          isSelected={'scheduleUpdates' === page}
          onClick={() => setPage('scheduleUpdates')}
        >
          Chef Schedule Updates
        </SidebarListItem>
        <SidebarListItem
          isSelected={'menuItemStatus' === page}
          onClick={() => setPage('menuItemStatus')}
        >
          Menu Item Updates
        </SidebarListItem>
        <SidebarListItem
          isSelected={'chefDirectory' === page}
          onClick={() => setPage('chefDirectory')}
        >
          Chef Directory
        </SidebarListItem>
        <SidebarListItem
          isSelected={'vendorClientMap' === page}
          onClick={() => setPage('vendorClientMap')}
        >
          Vendor / Client Map
        </SidebarListItem>
      </Sidebar>
    )
  }

  let heading = ''
  switch (page) {
    case 'orderStatus':
      heading = 'Chef Order Status'
      break
    case 'scheduleUpdates':
      heading = 'Chef Schedule Updates'
      break
    case 'menuItemStatus':
      heading = 'Menu Item Updates'
      break
    case 'chefDirectory':
      heading = 'Chef Directory'
      break
    case 'vendorClientMap':
      heading = 'Vendor / Client Map'
      break
  }

  return (
    <PageBody>
      {renderSideBar()}
      {/* PANEL */}
      <div className="sidebar-body">
        <div className="sidebar-body-inner">
          <Panel width="100%" maxWidth="1600px" heading={heading}>
            {page === 'orderStatus' && (
              <OrderStatus headquarters={headquarters} headquarterId={hqId} />
            )}
            {page === 'scheduleUpdates' && <ChefScheduleUpdates />}
            {page === 'menuItemStatus' && (
              <MenuItemUpdates
                headquarters={headquarters}
                headquarterId={hqId}
              />
            )}
            {page === 'chefDirectory' && (
              <ChefDirectory headquarters={headquarters} headquarterId={hqId} />
            )}
            {page === 'vendorClientMap' && <VendorClientMap />}
          </Panel>
        </div>
      </div>
    </PageBody>
  )
}

ChefNetworkPage.propTypes = {
  hqId: PropTypes.number,
  headquarters: PropTypes.array,
  userId: PropTypes.string,

  loadHeadquarters: PropTypes.func,
}

export default ChefNetworkPage
