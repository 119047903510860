const urlPrefix = '/hubspot'

export const LoadHubspotSingle =
  ({ RestService, HubspotService, pResponseHubspotSingle, endpoint }) =>
  async (hungryId) => {
    const urlString = `${urlPrefix}/${endpoint}/${hungryId}`
    try {
      const response = await RestService.get(urlString)
      HubspotService.setSingle(pResponseHubspotSingle(response))
    } catch (e) {
      HubspotService.setSingle(null)
    }
  }

export const LoadOrSearchHubspotList =
  ({
    RestService,
    HubspotService,
    pRequestHubspotList,
    pResponseHubspotList,
    endpoint,
  }) =>
  async ({ search, page, results_per_page }) => {
    const params = pRequestHubspotList({
      searchString: search,
      page,
      limit: results_per_page,
    })
    const urlString = `${urlPrefix}/${endpoint}`
    const response = await RestService.get(urlString, params)

    HubspotService.setList(pResponseHubspotList(response))

    return pResponseHubspotList(response)
  }

export const SetHubspotRelation =
  ({ RestService, HubspotService, pResponseHubspotSingle, endpoint }) =>
  async (hungryId, hubspotId, name) => {
    const urlString = `${urlPrefix}/${endpoint}/${hungryId}`
    const params = { hubspotId: hubspotId, hungryId: hungryId, name }
    const response = await RestService.post(urlString, params)

    HubspotService.setSingle(pResponseHubspotSingle(response))
  }

export const UpdateHubspotCompanies =
  ({ RestService, HubspotService, pResponseHubspotList, endpoint }) =>
  async (companies) => {
    try {
      companies.forEach((c) => {
        c.id = c.id.toString()
      })
      const response = await RestService.put(`${urlPrefix}/${endpoint}`, {
        inputs: companies,
      })
      HubspotService.setCompaniesInCompanies(pResponseHubspotList(response))

      return true
    } catch (e) {
      return false
    }
  }
