import React, { Component } from 'react'
import { config } from 'hungry-core2'
import Moment from 'moment-timezone'
import Flatpickr from 'react-flatpickr'
import {
  GroupOrderOrdersColumns,
  GroupOrderItemsColumns,
  GroupOrderDeliveryFeeType,
  GROUP_ORDER_SUBSIDY_TYPE_PERCENT,
  GROUP_ORDER_SUBSIDY_TYPE_REVERSE,
  GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET,
} from './constants'
import { AutocompleteInput } from '@containers/common/form'
import { DateMenus } from '@containers/groupOrders'
import {
  SubsidySettings,
  ClientGlobalSettings,
  EditOrderPartialRefund,
} from '@components/groupOrders'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import ButtonWithLink from '@components/common/form/ButtonWithLink'
import Checkbox from '@components/common/form/Checkbox'
import Dropdown from '@components/common/form/Dropdown'
import YSpacing from '@components/common/YSpacing'
import DividerLine from '@components/common/DividerLine'
import TextArea from '@components/common/form/TextArea'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import LabelInfo from '@components/common/form/LabelInfo'
import styled from '@emotion/styled'
import { AuthorizedInteractable } from '@containers/common/auth'
import { LoadingIndicator } from '@components/common'
import { ExportCSVButton } from '@containers/dashboard'
import {
  pGroupOrderItemsCSV,
  pGroupOrderOrdersCSV,
} from '../../../behavior/presenters/api/groupOrder'
import CurrencyInput from '../common/form/CurrencyInput'
import { colors } from '../../../constants'
import { isArraysEqual } from '../../../utils'
import { GetDeliveryTypeDisplayName } from '../../../utils/groupOrderUtils'

const DateFormat = 'LL'

const MENU_TAB = 'Menus'
const ORDERS_TAB = 'Orders'
const RACKS_TAB = 'Racks'
const AUDIT_LOGS_TAB = 'Audits'
const PAYMENTS_TAB = 'Payments'

const ManualPaymentTypes = ['ACH', 'Check']

const FEE_CHANGE_REASONS = [
  'Late Delivery',
  'Food/Order Issues',
  'Last-Minute Client Cancelation',
  'Other',
]

const DELIVERY_FEE_TYPES_REQUIRING_VALUE = [
  GroupOrderDeliveryFeeType.FIXED,
  GroupOrderDeliveryFeeType.PERCENT,
  GroupOrderDeliveryFeeType.PER_ORDER,
  GroupOrderDeliveryFeeType.UNDER_LIMIT,
]

// NOTE: admins should not be able to set delivery fee type to 'UnderLimit'
const DELIVERY_FEE_OPTIONS = [
  GroupOrderDeliveryFeeType.STANDARD,
  GroupOrderDeliveryFeeType.FIXED,
  GroupOrderDeliveryFeeType.PERCENT,
  GroupOrderDeliveryFeeType.PER_ORDER,
  GroupOrderDeliveryFeeType.WAIVE_FEE,
]

type Props = {
  email?: any
  headquarter?: any
  locale?: any
  location?: any
  client?: any
  groupOrderPage?: any
  groupOrderUrl?: any
  invoiceUrl?: any
  dateMenus?: any
  clientSettings?: any
  selectedIndex?: any
  clientGlobalSettings?: any
  user?: any

  addRefundToSubOrder?: any
  checkOrderIsEditableNow: (date: string | Moment.Moment | Date) => boolean
  deleteDateMenus?: any
  displayFailureMessage?: any
  displayInfoMessage?: any
  displayInfoMessageOptions?: any
  searchClientSettings?: any
  getAccountingOrder?: any
  getClientGlobalSettings?: any
  getGroupOrderAuditLogs?: any
  getGroupOrderBySettingsDate?: any
  isAuthorized: (obj: any, roles: string[]) => boolean
  clearGroupOrderPage?: any
  newClientSettings?: any
  newClientGlobalSettings?: any
  newContact?: any
  saveClientGlobalSettings?: any
  saveStaffingFeeSettings?: any
  saveDateMenus?: any
  searchDateMenus?: any
  searchSalesReps?: any
  markGroupOrderPaid?: any
  pGroupOrderOrdersCSV?: any
  pGroupOrderItemsCSV?: any
  refundOrderInGroupOrder?: any
  newSubsidySetting?: any
  setDateMenus?: any
  updateGroupOrderFees?: any
  searchAccountingOrders?: any
  searchGroupOrders?: any
  prepareGroupOrderInvoice?: any
  calculateCurrentDeliveryFees?: any
  updateClientSettings?: any
  updateSelectedIndex?: any
  onSaveClientSettings?: any
  onSaveClientGlobalSettings?: any
  orderBalancesEditCutoffDate: (
    date: string | Moment.Moment | Date,
  ) => Moment.Moment
  updateClientGlobalSettings?: any
  toggleDeliveryFeeSettingsModal: (value: boolean) => void
  toggleStaffingFeeSettingsModal: (value: boolean) => void
}

type State = {
  auditLogs: any
  filteredAuditLogs: any
  auditLogsId?: string
  dates: any
  groupOrder: any
  selectedDate: any
  orders: any
  countMap: any
  accountingOrder: any
  calculateCurrentDeliveryFeesLoading: boolean
  statusFilter: string
  activeTab: string
  subsidySettingsExpandedMap: any
  manualPmtType: any
  showOrder: any
  feeInfo: {
    changeSvc: boolean
    changeTip: boolean
    changeSvcReason: string | null
    changeTipReason: string | null
    adjustedSvcFee: number
    adjustedTipFee: number
  }
  viewCostUpdateHistory: boolean
  auditLogSearchText: string
  isLoadingAuditLogs: boolean
  ignoreBeforeCutoff: boolean
  editOrderPartialRefundOpen: boolean
}

type EditCostsValidation = {
  canEdit: boolean
  messages: string[]
}

class GroupOrdersPage extends Component<Props, State> {
  state = {
    auditLogs: [],
    filteredAuditLogs: [],
    auditLogsId: undefined,
    dates: {},
    groupOrder: undefined,
    selectedDate: Moment(),
    accountingOrder: null,
    calculateCurrentDeliveryFeesLoading: false,
    orders: [],
    countMap: {},
    statusFilter: 'Active',
    activeTab: MENU_TAB,
    subsidySettingsExpandedMap: {},
    manualPmtType: ManualPaymentTypes[0],
    showOrder: false,
    feeInfo: {
      changeSvc: false,
      changeTip: false,
      changeSvcReason: null,
      changeTipReason: null,
      adjustedSvcFee: 0,
      adjustedTipFee: 0,
    },
    viewCostUpdateHistory: false,
    auditLogSearchText: '',
    isLoadingAuditLogs: false,
    ignoreBeforeCutoff: false,
    editOrderPartialRefundOpen: false,
  }
  searchTimer: ReturnType<typeof setTimeout> | undefined = undefined

  componentDidMount() {
    const { client, clientSettings } = this.props

    this.loadClient(client)
    if (clientSettings?.length) {
      this.onLoadGroupOrders()
      this.onLoadDateMenus()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    //better than chaining callbacks
    const { selectedDate } = this.state
    const { selectedIndex: i, clientSettings, client } = this.props
    const clientChanged =
      !prevProps.client || prevProps.client?.id != client?.id
    const indexChanged = prevProps.selectedIndex != i
    const dateChanged = prevState.selectedDate.format() != selectedDate.format()
    const clientSettingsChanged = !isArraysEqual(
      prevProps.clientSettings,
      clientSettings,
      'id',
    )
    const hasId = clientSettings[i] && clientSettings[i].id

    if (clientChanged) {
      this.loadClient(client)
    }
    if (
      (clientChanged || clientSettingsChanged || indexChanged || dateChanged) &&
      hasId
    ) {
      this.onLoadGroupOrders()
      this.onLoadDateMenus()
      this.setState({ ignoreBeforeCutoff: false })
    }

    if (this.state.orders !== prevState.orders) {
      const countMap = this.state.orders.reduce((map: any, order: any) => {
        order.menus.forEach((menu: any) => {
          if (map[menu.menuId] === undefined) {
            map[menu.menuId] = 1
          } else {
            map[menu.menuId] += 1
          }
          menu.orderItems.forEach((item: any) => {
            if (map[item.menuItemId] === undefined) {
              map[item.menuItemId] = item.quantity
            } else {
              map[item.menuItemId] += item.quantity
            }
          })
        })

        return map
      }, {})
      this.setState({ countMap })
    }
  }

  componentWillUnmount() {
    const { clearGroupOrderPage } = this.props
    clearGroupOrderPage()
  }

  canEditCosts = (): EditCostsValidation => {
    const messages = [] as string[]
    const {
      checkOrderIsEditableNow,
      isAuthorized,
      orderBalancesEditCutoffDate,
      user,
    } = this.props
    const { groupOrder }: { groupOrder: any } = this.state
    if (!groupOrder || !user) {
      return { canEdit: false, messages }
    }

    const { dropoffTime } = groupOrder
    const hasMasterBalanceEditRole = isAuthorized(user, [
      'master admin',
      'finance',
    ])
    const hasSalesRole = isAuthorized(user, ['sales rep', 'sales lead'])
    if (!hasMasterBalanceEditRole && !hasSalesRole) {
      messages.push(
        'Must have one of the following roles to edit balances: sales rep, sales lead, finance, or master admin',
      )
    }
    const isBeforeEditCutoff = checkOrderIsEditableNow(dropoffTime)
    const editCutoff = orderBalancesEditCutoffDate(dropoffTime)
    if (!isBeforeEditCutoff) {
      messages.push(
        `The cutoff time for editing balances for this order has passed. The cutoff time was ${editCutoff.format(
          'MMMM Do YYYY, h:mm:ss a',
        )}. Please reach out to support if assistance is needed.`,
      )
    }
    const canEdit =
      hasMasterBalanceEditRole || (isBeforeEditCutoff && hasSalesRole)

    return { canEdit, messages }
  }

  onCalculateCurrentDeliveryFees = async () => {
    const { calculateCurrentDeliveryFeesLoading } = this.state
    const groupOrder = this.state.groupOrder as any
    if (calculateCurrentDeliveryFeesLoading || !groupOrder) {
      return
    }
    const params = {
      date: groupOrder.date,
      clientId: groupOrder.client.id,
    }
    this.setState({ calculateCurrentDeliveryFeesLoading: true })
    const updatedOrders = await this.props.calculateCurrentDeliveryFees(params)
    this.setState({ calculateCurrentDeliveryFeesLoading: false })
    // TODO: do we need to update other GO's too?
    const updatedOrder = updatedOrders.find(
      (order: any) => order.id === groupOrder.id,
    )
    if (updatedOrder) {
      // Result is just group order (no suborders), so use suborders from state
      const nextOrder = {
        ...updatedOrder,
        orders: groupOrder.orders,
      }
      this.setState({ groupOrder: nextOrder })
    }
  }

  onToggleAuditLogsTab = async () => {
    const { groupOrder } = this.state

    if (groupOrder) {
      this.onLoadAuditLogs((groupOrder as any).id)
    }
    this.setState({ activeTab: AUDIT_LOGS_TAB })
  }

  onLoadAuditLogs = async (groupOrderId: string) => {
    this.setState({ isLoadingAuditLogs: true })
    const auditLogs = await this.props.getGroupOrderAuditLogs(groupOrderId)
    this.setState({
      isLoadingAuditLogs: false,
      auditLogs,
      filteredAuditLogs: [],
      auditLogSearchText: '',
      auditLogsId: groupOrderId,
    })
  }

  onTogglePaymentsTab = async () => {
    const { groupOrder } = this.state
    const { getAccountingOrder } = this.props

    let newState = { ...this.state, activeTab: PAYMENTS_TAB }
    if (groupOrder) {
      const accountingOrder = await getAccountingOrder((groupOrder as any).id)
      if (accountingOrder) {
        newState = { ...newState, accountingOrder }
      }
    }
    this.setState(newState)
  }

  onSearchAuditLogs = (e: any) => {
    const { auditLogs } = this.state
    const auditLogSearchText = e.target.value
    const rgxSearch = new RegExp(auditLogSearchText, 'ig')
    const filteredAuditLogs = auditLogs.filter((log: any) => {
      const timestampMatch = log.timestamp
        .format('MMM D YYYY h:mm:ss a')
        .match(rgxSearch)
      const changesMatch = log.changes.some(
        (diff: any) =>
          diff.key.match(rgxSearch) || diff.value.toString().match(rgxSearch),
      )

      return timestampMatch || changesMatch
    })

    this.setState({ filteredAuditLogs, auditLogSearchText })
  }

  onSearchGroupOrders = async (params: any) => {
    const result = await this.props.searchGroupOrders(params)

    return result
  }

  onPrepareGroupOrderInvoice = async (groupOrderIds: string[]) => {
    if (!this.props.client) {
      // TODO: show flash error

      return
    }
    const clientId = this.props.client.id
    const result = await this.props.prepareGroupOrderInvoice({
      groupOrderIds,
      clientId,
    })

    return result
  }

  onMarkPaid = async (id: any, number: any) => {
    const { manualPmtType: paymentType } = this.state
    const { markGroupOrderPaid, email: alias } = this.props
    if (await markGroupOrderPaid({ id, number, paymentType, alias })) {
      this.setState((state) => ({
        groupOrder: { ...state.groupOrder, status: 'Paid' },
      }))
    }
  }

  onRefundOrder = async (order: any) => {
    const { refundOrderInGroupOrder } = this.props
    const { statusFilter, groupOrder: group } = this.state
    const groupOrder = await refundOrderInGroupOrder(order, (group as any).id)
    if (groupOrder) {
      this.setState({
        groupOrder,
        orders: groupOrder.orders.filter((o: any) => o.status === statusFilter),
      })
    }
  }

  onReplaceOrder = async (orderId: string, updatedOrder: any) => {
    let groupOrder = this.state.groupOrder as any
    const idx = groupOrder.orders.findIndex((o: any) => o.id === orderId)
    groupOrder = {
      ...groupOrder,
      orders: [
        ...groupOrder.orders.slice(0, idx),
        updatedOrder,
        ...groupOrder.orders.slice(idx + 1),
      ],
    }

    this.setState({
      groupOrder,
      orders: groupOrder.orders.filter(
        (o: any) => o.status === this.state.statusFilter,
      ),
    })
  }

  loadClient = async (client: any) => {
    if (!client) {
      return
    }
    const { groupOrderPage } = this.props
    const { date } = groupOrderPage || {}
    const newState = { ...this.state }
    if (date) {
      newState.selectedDate = Moment(date)
      newState.activeTab = ORDERS_TAB
    }
    this.setState(newState)
  }

  onSelectDate = (selectedDate: any) => {
    //pass Moment
    this.setState({ selectedDate })
  }

  onLoadGroupOrders = async () => {
    const { activeTab, selectedDate, statusFilter } = this.state
    const { selectedIndex: i, clientSettings } = this.props
    const groupOrder = await this.props.getGroupOrderBySettingsDate({
      settingsId: clientSettings[i].id,
      date: selectedDate.format('YYYY-MM-DD'),
    })
    if (groupOrder) {
      let newState = {
        ...this.state,
        groupOrder,
        orders: groupOrder.orders.filter((o: any) => o.status === statusFilter),
      }
      if (activeTab === AUDIT_LOGS_TAB) {
        this.onLoadAuditLogs(groupOrder.id)
      } else if (activeTab === PAYMENTS_TAB) {
        const accountingOrder = await this.props.getAccountingOrder(
          groupOrder.id,
        )
        if (accountingOrder) {
          newState = { ...newState, accountingOrder }
        }
      }

      this.setState(newState)
    } else {
      this.setState({
        groupOrder: undefined,
        orders: [],
      })
    }
  }

  onLoadDateMenus = async () => {
    const { selectedDate } = this.state
    const { locale, selectedIndex: i, clientSettings } = this.props
    const start = selectedDate.clone().startOf('month')
    const end = selectedDate.clone().endOf('month').endOf('day')
    const datesArr = await this.props.searchDateMenus({
      settingsId: clientSettings[i].id,
      start,
      end,
    })
    const otherDateMenus = this.props.dateMenus.filter(
      (dm: any) => dm.clientSettingsId !== clientSettings[i].id,
    )
    this.props.setDateMenus([...otherDateMenus, ...datesArr])
    const dates = datesArr.reduce((acc: any, dateMenu: any) => {
      acc[Moment(dateMenu.date).tz(locale).format(DateFormat)] = dateMenu

      return acc
    }, {})
    this.setState({ dates })
  }

  onChangeClientSettings = (field: string, value: any) => {
    const updates = {
      [field]: value,
    }
    // When changing delivery fee type field, add default value if "percent"
    // delivery type selected
    if (field === 'deliveryFeeType') {
      if (value === GroupOrderDeliveryFeeType.PERCENT) {
        updates.deliveryFee = 12.5
      } else if (
        value === GroupOrderDeliveryFeeType.STANDARD ||
        value === GroupOrderDeliveryFeeType.WAIVE_FEE
      ) {
        updates.deliveryFee = 0
      }
    }
    const {
      selectedIndex: i,
      clientSettings,
      updateClientSettings,
    } = this.props
    updateClientSettings([
      ...clientSettings.slice(0, i),
      { ...clientSettings[i], ...updates },
      ...clientSettings.slice(i + 1),
    ])
  }

  onChangeClientGlobalSettings = (field: string, value: any) => {
    const { clientGlobalSettings } = this.props
    const nextGlobalSettings = {
      ...clientGlobalSettings,
      [field]: value,
    }
    this.props.updateClientGlobalSettings(nextGlobalSettings)
  }

  onChangeGORacks = (field: string, value: any) => {
    const groupOrder = this.state.groupOrder as any
    this.setState({
      groupOrder: {
        ...groupOrder,
        [field]: value,
      },
    })
  }

  onChangeSubsidySettings = (index: number, field: string, value: any) => {
    const {
      selectedIndex: i,
      clientSettings,
      updateClientSettings,
    } = this.props

    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        subsidySettings: {
          subsidies: [
            ...clientSettings[i].subsidySettings.subsidies.slice(0, index),
            {
              ...clientSettings[i].subsidySettings.subsidies[index],
              [field]: value,
            },
            ...clientSettings[i].subsidySettings.subsidies.slice(index + 1),
          ],
        },
      },
      ...clientSettings.slice(i + 1),
    ]

    updateClientSettings(updatedSettings)
  }

  onChangeLocation = (id: string) => {
    const {
      client: loadedClient,
      locale,
      selectedIndex: i,
      clientSettings,
      updateClientSettings,
    } = this.props
    const location = loadedClient.addresses.find((addr: any) => addr.id === id)
    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        location: {
          id: location.id,
          name: location.fullAddress,
          line1: location.line1,
          line2: location.line2,
          city: location.city,
          state: location.state,
          zip: location.zip,
          geolocation: location.geolocation,
          locale,
          tolls: location.allowTolls,
          buildingInstructions: location.hungryBuildingInstructions,
          spot: '',
        },
      },
      ...clientSettings.slice(i + 1),
    ]

    updateClientSettings(updatedSettings)
  }

  onChangeContact = (id: any) => {
    const {
      client: loadedClient,
      newContact,
      selectedIndex: i,
      clientSettings,
      updateClientSettings,
    } = this.props
    let contact
    const match = loadedClient.contacts.find((ct: any) => ct.id === id)
    if (match) {
      contact = newContact(match)
    }
    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        client: {
          ...clientSettings[i].client,
          contact,
        },
      },
      ...clientSettings.slice(i + 1),
    ]
    updateClientSettings(updatedSettings)
  }

  onChangeSalesRep = (salesRep: any) => {
    const {
      clientSettings,
      selectedIndex: i,
      updateClientSettings,
    } = this.props
    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        salesRep: {
          id: salesRep.id,
          firstName: salesRep.firstName,
          lastName: salesRep.lastName,
          email: salesRep.email,
          phone: salesRep.phoneNumber,
        },
      },
      ...clientSettings.slice(i + 1),
    ]
    updateClientSettings(updatedSettings)
  }

  onAddNewClientSettings = () => {
    const {
      newClientSettings,
      headquarter,
      client,
      clientSettings,
      updateClientSettings,
      updateSelectedIndex,
    } = this.props
    updateClientSettings([
      ...clientSettings,
      newClientSettings({ headquarter, client }),
    ])
    updateSelectedIndex(clientSettings.length)
    this.setState({
      dates: {},
      groupOrder: undefined,
      orders: [],
    })
  }

  onAddNewSubsidySettings = () => {
    const {
      clientSettings,
      selectedIndex: i,
      updateClientSettings,
    } = this.props
    const { newSubsidySetting } = this.props
    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        subsidySettings: {
          subsidies: [
            ...clientSettings[i].subsidySettings.subsidies,
            newSubsidySetting(),
          ],
        },
      },
      ...clientSettings.slice(i + 1),
    ]
    updateClientSettings(updatedSettings)
  }

  onRemoveSubsidySettings = (index: number) => {
    const {
      clientSettings,
      selectedIndex: i,
      updateClientSettings,
    } = this.props
    const updatedSettings = [
      ...clientSettings.slice(0, i),
      {
        ...clientSettings[i],
        subsidySettings: {
          subsidies: [
            ...clientSettings[i].subsidySettings.subsidies.slice(0, index),
            ...clientSettings[i].subsidySettings.subsidies.slice(index + 1),
          ],
        },
      },
      ...clientSettings.slice(i + 1),
    ]
    updateClientSettings(updatedSettings)
  }

  onReloadDates = () => {
    this.onLoadGroupOrders()
    this.onLoadDateMenus()
    const countMap = this.state.orders.reduce((map: any, order: any) => {
      order.menus.forEach((menu: any) => {
        if (map[menu.menuId] === undefined) {
          map[menu.menuId] = 1
        } else {
          map[menu.menuId] += 1
        }
        menu.orderItems.forEach((item: any) => {
          if (map[item.menuItemId] === undefined) {
            map[item.menuItemId] = item.quantity
          } else {
            map[item.menuItemId] += item.quantity
          }
        })
      })

      return map
    }, {})
    this.setState({ countMap })
  }

  onToggleSubsidySettings = (index: number) => {
    const { subsidySettingsExpandedMap }: { subsidySettingsExpandedMap: any } =
      this.state
    this.setState({
      subsidySettingsExpandedMap: {
        ...subsidySettingsExpandedMap,
        [index]: !subsidySettingsExpandedMap[index],
      },
    })
  }

  checkChefHasOrders = (chef: any) => {
    const { groupOrder }: { groupOrder: any } = this.state
    const { orders } = groupOrder

    return orders.some((order: any) =>
      order.menus.some((menu: any) => menu.chefId === chef.id),
    )
  }

  renderRackDetails = () => {
    const { ignoreBeforeCutoff } = this.state
    const { groupOrder }: { groupOrder: any } = this.state
    const { dateMenus, displayInfoMessageOptions } = this.props

    if (!groupOrder) {
      return <FlexContainer />
    }

    const { cutoffTime } = groupOrder
    const isDraftRoster = cutoffTime && Moment().isBefore(Moment(cutoffTime))
    const dateMenu = dateMenus.find(
      (dm: any) => dm.id === groupOrder.dateMenusId,
    )
    const allMenuIts =
      (dateMenu &&
        dateMenu.menus
          .map((menu: any) =>
            menu.menuItems.map((it: any) => ({
              ...it,
              chefName: menu.chef.name,
            })),
          )
          .flatten()) ||
      []

    return (
      <FlexContainer
        justifyContent="space-between"
        flexDirection="column"
        marginTop="20px"
      >
        <YSpacing height="10px" />
        {!groupOrder.usesRacks && <p className="bold">Racks not used</p>}

        {groupOrder.usesRacks && groupOrder.racks && (
          <FlexContainer justifyContent="space-between" flexDirection="column">
            <FlexContainer
              flexDirection="column"
              justifyContent="space-between"
            >
              <YSpacing height="15px" />

              <FlexContainer
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <LabelInfo
                  width="30%"
                  label="# Racks"
                  value={groupOrder.racks.count}
                />
                <LabelInfo
                  width="30%"
                  label="Overflow Racks"
                  value={groupOrder.racks.overflowCount}
                />
                <LabelInfo
                  width="30%"
                  label="Shelves Per Rack"
                  value={groupOrder.racks.shelvesPerRack}
                />
              </FlexContainer>

              <YSpacing height="5px" />
              <FlexContainer
                flexDirection="column"
                justifyContent="space-between"
              >
                {allMenuIts
                  .filter((it: any) => it.countPerShelf === 0)
                  .map((it: any) => (
                    <p className="bold gradient-bg m-2" key={it.id}>
                      {it.chefName} - {it.name} does not have a count per shelf
                      set
                    </p>
                  ))}
              </FlexContainer>

              <YSpacing height="5px" />

              {isDraftRoster && !ignoreBeforeCutoff && (
                <p
                  className="download-pdf ml-2 cursor-pointer"
                  onClick={() =>
                    displayInfoMessageOptions(
                      `Roster sheet is not finalized. Are you sure you want to download now? Roster finalized at ${Moment(
                        cutoffTime,
                      ).format('YYYY-MM-DD h:mma')}`,
                      {
                        buttonAction: () =>
                          this.setState({ ignoreBeforeCutoff: true }),
                        buttonTitle: 'Confirm',
                      },
                    )
                  }
                >
                  Roster Sheet {isDraftRoster ? '(DRAFT PREVIEW)' : ''}
                </p>
              )}
              {(!isDraftRoster || ignoreBeforeCutoff) && (
                <a
                  className="download-pdf ml-2"
                  href={`${config.api_host}/group-orders/ops/roster-sheet/${groupOrder.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Roster Sheet {isDraftRoster ? '(DRAFT PREVIEW)' : ''}
                </a>
              )}

              {groupOrder.chefs
                .filter((chef: any) => this.checkChefHasOrders(chef))
                .map((chef: any) => (
                  <a
                    className="download-pdf ml-2"
                    key={chef.id}
                    href={`${config.api_host}/chef-dashboard/${groupOrder.id}/pdf?pdfType=GroupOrderTags&orderType=Group Order&chefId=${chef.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chef.name} Food Labels
                  </a>
                ))}
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    )
  }

  renderFeeChangeReasons = (onChange: any) => {
    return (
      <div>
        <select className="w-4/5" onChange={onChange}>
          <option key="default" value={null as any} defaultValue={null as any}>
            Reason
          </option>
          {FEE_CHANGE_REASONS.map((reason) => (
            <option key={reason} value={reason}>
              {reason}
            </option>
          ))}
        </select>
      </div>
    )
  }

  onFlipChangeFee = (type: any) => {
    const { feeInfo } = this.state
    const changeFee = (feeInfo as any)[`change${type}`]

    if (changeFee) {
      this.setState({
        feeInfo: {
          ...feeInfo,
          [`change${type}`]: false,
          [`change${type}Reason`]: null,
          [`adjusted${type}Fee`]: 0,
        },
      })
    } else {
      this.setState({
        feeInfo: {
          ...feeInfo,
          [`change${type}`]: true,
        },
      })
    }
  }

  onChangeFeeReason = (type: any, e: any) => {
    const { feeInfo } = this.state
    const reason = e.target.value

    this.setState({
      feeInfo: {
        ...feeInfo,
        [`change${type}Reason`]: reason,
      },
    })
  }

  onAdjustFee = (type: any, value: any) => {
    const { feeInfo } = this.state

    this.setState({
      feeInfo: {
        ...feeInfo,
        [`adjusted${type}Fee`]: value,
      },
    })
  }

  onUpdateFees = async (id: any) => {
    const { updateGroupOrderFees } = this.props
    const { feeInfo } = this.state

    const groupOrder = await updateGroupOrderFees({ id, feeInfo })
    if (groupOrder) {
      this.setState({
        groupOrder,
        feeInfo: {
          changeSvc: false,
          changeSvcReason: null,
          adjustedSvcFee: 0,
          changeTip: false,
          changeTipReason: null,
          adjustedTipFee: 0,
        },
      })
    }
  }

  calcNewTotal = () => {
    const { feeInfo } = this.state
    const { groupOrder }: { groupOrder: any } = this.state
    const { changeSvcReason, adjustedSvcFee, changeTipReason, adjustedTipFee } =
      feeInfo

    let total = parseFloat(groupOrder.total)
    total = changeSvcReason
      ? total -
        parseFloat(groupOrder.serviceFee) +
        parseFloat(adjustedSvcFee as any)
      : total
    total = changeTipReason
      ? total - parseFloat(groupOrder.tip) + parseFloat(adjustedTipFee as any)
      : total

    return total.toFixed(2)
  }

  getShouldShowCalcDeliveryFeesButton = () => {
    const { groupOrder }: { groupOrder: any } = this.state
    if (!groupOrder) {
      return false
    }
    const format = 'YYYY-MM-DD'
    // TODO: may need to consider user timezone here
    const nowDate = Moment().format(format)
    const orderDate = Moment(groupOrder.date)

    return orderDate.isSameOrAfter(nowDate)
  }

  getDeliveryFeeDisplayValue = (groupOrder: any) => {
    if (
      groupOrder.deliveryFeeSettings &&
      groupOrder.deliveryFeeSettings.feeBundledWith
    ) {
      return `See ${groupOrder.deliveryFeeSettings.feeBundledWith}`
    }

    return `$${parseFloat(groupOrder.deliveryFee || '0').toFixed(2)}`
  }

  renderCostUpdateHistory = (order: any) => {
    if (!order.costUpdates) {
      return
    }

    const { changeSvcReason, changeTipReason } = order.costUpdates

    return (
      <div className="flex-row">
        {changeSvcReason && (
          <p className="font-bold">
            Service Fee History<br></br>
            <span key={changeSvcReason} className="font-normal">
              {changeSvcReason}
              <br></br>
            </span>
          </p>
        )}
        {changeTipReason && (
          <p className="font-bold">
            Tip History<br></br>
            <span key={changeTipReason} className="font-normal">
              {changeTipReason}
              <br></br>
            </span>
          </p>
        )}
      </div>
    )
  }

  renderOrderDetails = () => {
    const { groupOrderUrl, invoiceUrl, clientSettings, selectedIndex } =
      this.props
    const {
      statusFilter,
      showOrder,
      manualPmtType,
      orders,
      feeInfo,
      viewCostUpdateHistory,
      editOrderPartialRefundOpen,
    } = this.state
    const { groupOrder }: { groupOrder: any } = this.state
    const {
      changeSvc,
      changeTip,
      changeSvcReason,
      changeTipReason,
      adjustedSvcFee,
      adjustedTipFee,
    } = feeInfo

    const { subsidySettings, clientGlobalSettings } = groupOrder || {}

    const {
      fullySubsidized,
      itemPriceSettings,
      limitPurchase,
      perOrderSubsidy,
      subsidyPercentLimitAmt,
      isSubsidyPercentLimit,
      subsidyType,
    } = subsidySettings || {}

    const { hungryWalletSettings, itemLimitMap, limitItems } =
      clientGlobalSettings || {}

    const priceSettings = itemPriceSettings || {}

    const priceSettingsBudgets = (priceSettings && priceSettings.budgets) || {}
    const priceSettingsPrices = (priceSettings && priceSettings.prices) || {}
    const { canEdit: canEditCosts, messages: editCostsMessages } =
      this.canEditCosts()

    return (
      <div>
        {groupOrder ? (
          <div>
            <YSpacing height="20px" />
            <Dropdown
              label="Order Status"
              value={statusFilter}
              onChange={(e) => {
                const statusCode = e.target.value
                const orders = groupOrder.orders.filter(
                  (o: any) => o.status === statusCode,
                )
                this.setState({ orders, statusFilter: statusCode })
              }}
            >
              <option value="Active">Active</option>
              <option value="Cancelled">Cancelled</option>
            </Dropdown>
            <div>
              <FlexContainer justifyContent="space-between">
                <ButtonWithLink
                  label="Download Invoice"
                  padding="10px"
                  link={`${groupOrderUrl}/${groupOrder.id}/invoice_pdf`}
                />
                <ButtonWithLink
                  label="Payment Form Link"
                  padding="10px"
                  link={`${invoiceUrl}/${groupOrder.id}`}
                />
              </FlexContainer>
              <YSpacing height="20px" />
              <FlexContainer justifyContent="space-between">
                <LabelInfo
                  width="48%"
                  label="Group Order #"
                  value={groupOrder.number}
                />
                <LabelInfo
                  width="48%"
                  label="Status"
                  value={groupOrder.status}
                />
              </FlexContainer>
              <YSpacing height="10px" />
              <FlexContainer
                flexDirection="column"
                justifyContent="space-between"
              >
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    width="48%"
                    label="Effective Date"
                    value={Moment(groupOrder.effectiveDate).format(
                      'MM/DD/YYYY',
                    )}
                  />
                  <LabelInfo
                    width="48%"
                    label="Due Date"
                    value={Moment(groupOrder.dueDate).format('MM/DD/YYYY')}
                  />
                </FlexContainer>
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    width="48%"
                    label="Subsidy Type"
                    value={subsidyType}
                  />
                  <LabelInfo
                    width="48%"
                    label="Fully Sibsidized?"
                    value={fullySubsidized ? 'Yes' : 'No'}
                  />
                </FlexContainer>
                <LabelInfo
                  width="48%"
                  label="Limit Purchase?"
                  value={limitPurchase ? 'Yes' : 'No'}
                />
                <YSpacing height="10px" />
                {subsidyType === GROUP_ORDER_SUBSIDY_TYPE_REVERSE ? (
                  <FlexContainer
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <FlexContainer
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <div>
                        {' '}
                        <span className="font-bold">FLAT PRICE</span>
                      </div>
                      {Object.keys(priceSettingsPrices).map(
                        (priceSettingsType, oIndex) => {
                          const priceSetting =
                            priceSettingsPrices[priceSettingsType] || {}

                          return (
                            <div key={oIndex}>
                              {' '}
                              <span className="font-bold">
                                {priceSettingsType}:
                              </span>{' '}
                              {`$${priceSetting.price}`}{' '}
                            </div>
                          )
                        },
                      )}
                    </FlexContainer>
                    <YSpacing height="10px" />
                    <FlexContainer
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <div>
                        {' '}
                        <span className="font-bold">
                          CLIENT BUDGET PER EMPLOYEE
                        </span>
                      </div>
                      {Object.keys(priceSettingsBudgets).map(
                        (priceSettingsType, oIndex) => {
                          const priceSetting =
                            priceSettingsBudgets[priceSettingsType] || {}

                          return (
                            <div key={oIndex}>
                              {' '}
                              <span className="font-bold">
                                {priceSettingsType}:
                              </span>{' '}
                              {`$${priceSetting.budget}`}{' '}
                            </div>
                          )
                        },
                      )}
                    </FlexContainer>
                  </FlexContainer>
                ) : (
                  <FlexContainer>
                    {subsidyType !== GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET ? (
                      <FlexContainer
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <div>
                          {' '}
                          <span className="font-bold">
                            Per order subsidy:
                          </span>{' '}
                          {`${
                            subsidyType === GROUP_ORDER_SUBSIDY_TYPE_PERCENT
                              ? '%'
                              : '$'
                          }${perOrderSubsidy}`}{' '}
                        </div>
                        {isSubsidyPercentLimit && (
                          <div>
                            {' '}
                            <span className="font-bold">
                              Subsidy Percent Limit Amt:
                            </span>{' '}
                            {`$${subsidyPercentLimitAmt}`}{' '}
                          </div>
                        )}
                      </FlexContainer>
                    ) : (
                      <FlexContainer
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <div>
                          {' '}
                          <span className="font-bold">
                            HUNGRY Wallet Type:
                          </span>{' '}
                          {hungryWalletSettings.durationType}{' '}
                        </div>
                        <div>
                          {' '}
                          <span className="font-bold">
                            Wallet Amount Per Customer:
                          </span>{' '}
                          {`$${hungryWalletSettings.amount}`}{' '}
                        </div>
                      </FlexContainer>
                    )}
                  </FlexContainer>
                )}
                {!!limitItems && (
                  <FlexContainer
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <YSpacing height="10px" />
                    <div>
                      {' '}
                      <span className="font-bold">ITEM LIMITS</span>
                    </div>
                    {Object.keys(itemLimitMap).map((itemLimitType, oIndex) => {
                      const itemLimit = itemLimitMap[itemLimitType] || {}

                      return (
                        <div key={oIndex}>
                          {' '}
                          <span className="font-bold">
                            {itemLimitType}:
                          </span>{' '}
                          {itemLimit.quantity}{' '}
                        </div>
                      )
                    })}
                  </FlexContainer>
                )}
                {this.getShouldShowCalcDeliveryFeesButton() && (
                  <FlexContainer
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <YSpacing height="10px" />
                    <Button
                      label="Calculate current delivery fees"
                      padding="10px"
                      onClick={this.onCalculateCurrentDeliveryFees}
                      disabled={this.state.calculateCurrentDeliveryFeesLoading}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>
              <YSpacing height="20px" />
              <FlexContainer flexDirection="row" justifyContent="space-between">
                <FlexContainer width="66%" flexDirection="column">
                  <div>
                    {' '}
                    <span className="font-bold">Total Subsidy:</span> $
                    {parseFloat(groupOrder.totalSubsidy).toFixed(2)}{' '}
                  </div>
                  <div>
                    {' '}
                    <span className="font-bold">Total Wallet Amount:</span> $
                    {parseFloat(groupOrder.totalWalletAmount || '0').toFixed(2)}{' '}
                  </div>
                  <div>
                    {' '}
                    <span className="font-bold">Delivery Fee:</span>{' '}
                    {this.getDeliveryFeeDisplayValue(groupOrder)}{' '}
                  </div>
                  <div>
                    {' '}
                    <span className="font-bold">Staffing Fee:</span> $
                    {parseFloat(groupOrder.staffingFee || '0').toFixed(2)}{' '}
                  </div>
                  <div>
                    <span className="font-bold">Total Service Fee:</span> $
                    {parseFloat(groupOrder.serviceFee).toFixed(2)}
                    {canEditCosts && (
                      <div className="flex flex-col w-full">
                        <div className="flex-row w-full">
                          <input
                            id="changeSvcFee"
                            name="changeSvcFee"
                            type="checkbox"
                            checked={changeSvc}
                            onChange={() => this.onFlipChangeFee('Svc')}
                          />
                          <label htmlFor="changeSvcFee" className="ml-2">
                            Change Service Fee?
                          </label>
                        </div>
                        {changeSvc &&
                          this.renderFeeChangeReasons((e: any) =>
                            this.onChangeFeeReason('Svc', e),
                          )}
                        {changeSvc && changeSvcReason && (
                          <CurrencyInput
                            width="100%"
                            label="Adjusted Service Fee"
                            value={adjustedSvcFee}
                            onChange={(adjustedSvcFee: any) =>
                              this.onAdjustFee('Svc', adjustedSvcFee)
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {' '}
                    <span className="font-bold">Tax:</span> $
                    {parseFloat(groupOrder.tax).toFixed(2)}{' '}
                  </div>
                  <div>
                    <span className="font-bold">Tip:</span> $
                    {parseFloat(groupOrder.tip).toFixed(2)}
                    {canEditCosts && (
                      <div className="flex flex-col w-full">
                        <div className="flex-row w-full">
                          <input
                            id="changeTipFee"
                            name="changeTipFee"
                            type="checkbox"
                            checked={changeTip}
                            onChange={() => this.onFlipChangeFee('Tip')}
                          />
                          <label htmlFor="changeTipFee" className="ml-2">
                            Change Tip Fee?
                          </label>
                        </div>
                        {changeTip &&
                          this.renderFeeChangeReasons((e: any) =>
                            this.onChangeFeeReason('Tip', e),
                          )}
                        {changeTip && changeTipReason && (
                          <CurrencyInput
                            width="100%"
                            label="Adjusted Tip Fee"
                            value={adjustedTipFee}
                            onChange={(adjustedTipFee: any) =>
                              this.onAdjustFee('Tip', adjustedTipFee)
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {' '}
                    <span className="font-bold">Total:</span> $
                    {parseFloat(groupOrder.total).toFixed(2)}{' '}
                  </div>
                  {changeSvcReason && (
                    <div>
                      {' '}
                      <span className="font-bold">New Service Fee:</span> $
                      {adjustedSvcFee.toFixed(2)}{' '}
                    </div>
                  )}
                  {changeTipReason && (
                    <div>
                      {' '}
                      <span className="font-bold">New Tip:</span> $
                      {adjustedTipFee.toFixed(2)}{' '}
                    </div>
                  )}
                  {(changeSvcReason || changeTipReason) && (
                    <div>
                      {' '}
                      <span className="font-bold">New Total:</span> $
                      {this.calcNewTotal()}{' '}
                    </div>
                  )}
                  {(changeSvcReason || changeTipReason) && canEditCosts && (
                    <div>
                      <Button
                        label="Update Fees"
                        padding="10px"
                        onClick={() => this.onUpdateFees(groupOrder.id)}
                      />
                    </div>
                  )}
                  {canEditCosts && (
                    <div className="mt-2">
                      <Button
                        label={`${
                          viewCostUpdateHistory ? 'Hide' : 'View'
                        } Update History`}
                        padding="8px"
                        onClick={() =>
                          this.setState({
                            viewCostUpdateHistory: !viewCostUpdateHistory,
                          })
                        }
                      />
                      {viewCostUpdateHistory &&
                        this.renderCostUpdateHistory(groupOrder)}
                    </div>
                  )}
                  {Boolean(
                    editCostsMessages && editCostsMessages.length > 0,
                  ) && (
                    <div className="mt-4">
                      {editCostsMessages.map((editCostMessage, msgIdx) => (
                        <li key={msgIdx} className="font-bold">
                          {editCostMessage}
                        </li>
                      ))}
                    </div>
                  )}
                </FlexContainer>
                {groupOrder && groupOrder.status !== 'Paid' && (
                  <FlexContainer
                    justifyContent="flex-end"
                    flexDirection="column"
                    width="33%"
                  >
                    <AuthorizedInteractable
                      roles={['master admin', 'sales rep', 'sales lead']}
                    >
                      <Button
                        label="Mark Paid"
                        padding="10px"
                        onClick={() =>
                          this.onMarkPaid(groupOrder.id, groupOrder.number)
                        }
                      />
                    </AuthorizedInteractable>
                    <select
                      value={manualPmtType}
                      onChange={(e) =>
                        this.setState({ manualPmtType: e.target.value })
                      }
                    >
                      {ManualPaymentTypes.map((pmtType) => (
                        <option key={pmtType} value={pmtType}>
                          {' '}
                          {pmtType}{' '}
                        </option>
                      ))}
                    </select>
                  </FlexContainer>
                )}
              </FlexContainer>
            </div>
            <YSpacing height="10px" />
            <DividerLine />
            <YSpacing height="20px" />
          </div>
        ) : (
          <div>
            <YSpacing height="20px" />
            <p>There are no orders yet</p>
          </div>
        )}
        {orders.length > 0 && (
          <div>
            <label className="sidebar-body-label">
              Orders (Click to see details)
            </label>
            <YSpacing height="10px" />
          </div>
        )}
        <FlexContainer flexDirection="row" justifyContent="space-between">
          <ExportCSVButton
            data={pGroupOrderOrdersCSV(
              orders,
              clientSettings[selectedIndex].name,
            )}
            columns={GroupOrderOrdersColumns}
            filename={`${groupOrder && groupOrder.number}-All-Orders.csv`}
            text="Export Orders"
          />
          <ExportCSVButton
            data={pGroupOrderItemsCSV(
              orders,
              clientSettings[selectedIndex].name,
            )}
            columns={GroupOrderItemsColumns}
            filename={`${groupOrder && groupOrder.number}-All-Items.csv`}
            text="Export Items"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        {orders.map((order: any) => {
          const link = `www.onlineorder.tryhungry.com/guest/${groupOrder.groupOrderInviteCode}`
          const items = order.menus
            .map((menu: any) => menu.orderItems)
            .flatten()

          return (
            <div
              key={order.id}
              className="group-menu-container"
              onClick={() => this.setState({ showOrder: order.id })}
            >
              <label className="group-menu-name">
                {order.orderNumber} | {order.status} | Total $
                {Number(order.total).toFixed(2)}
              </label>
              {showOrder === order.id && (
                <div>
                  <div>
                    {`${order.customer.firstName} ${order.customer.lastName}`} |{' '}
                    {order.customer.email}
                  </div>
                  <DividerLine margin="10px 0" />
                  <FlexContainer flexDirection="column">
                    <p>
                      Customer Type:{' '}
                      {order.customer.id === 'guest' ? 'Guest' : 'Standard'}
                    </p>
                    <p>
                      Guest Link:{' '}
                      {order.customer.id === 'guest' ? (
                        <a href={link}>{link}</a>
                      ) : (
                        'N/A '
                      )}
                    </p>
                  </FlexContainer>
                  <DividerLine margin="10px 0" />
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    {order.status !== 'Cancelled' && (
                      <AuthorizedInteractable
                        roles={['master admin', 'sales rep', 'sales lead']}
                      >
                        <Button
                          label="Cancel & Notify Customer"
                          padding="10px"
                          margin="10px"
                          onClick={() => this.onRefundOrder(order)}
                        />
                      </AuthorizedInteractable>
                    )}
                    {order.status !== 'Cancelled' && (
                      <AuthorizedInteractable roles={['master admin']}>
                        <Button
                          label="Partial Refund Order"
                          padding="10px"
                          margin="10px"
                          onClick={() =>
                            this.setState({ editOrderPartialRefundOpen: true })
                          }
                        />
                      </AuthorizedInteractable>
                    )}
                  </FlexContainer>
                  {editOrderPartialRefundOpen && (
                    <EditOrderPartialRefund
                      orderId={order.id}
                      orderNumber={order.orderNumber}
                      closeModal={() =>
                        this.setState({ editOrderPartialRefundOpen: false })
                      }
                      displayInfoMessage={this.props.displayInfoMessage}
                      addRefundToSubOrder={this.props.addRefundToSubOrder}
                      onSave={(order) => this.onReplaceOrder(order.id, order)}
                    />
                  )}
                  <DividerLine margin="10px 0" />
                  <FlexContainer
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <FlexContainer flexDirection="column">
                      <div>Subtotal: ${order.subtotal}</div>
                      <div>Tax: ${order.tax}</div>
                      <div>Tip: ${order.tip}</div>
                      <div>Total: ${order.total}</div>
                      {Boolean(order.refunds?.length) && (
                        <div className="mt-5">Refunds:</div>
                      )}
                      {(order.refunds || []).map((refund: any) => (
                        <div key={refund.id}>
                          <div>
                            {' '}
                            {Moment(refund.createdAt).format('lll')} - $
                            {parseFloat(refund.amount).toFixed(2)}{' '}
                          </div>
                          <p className="pl-4">
                            {refund.processor} ID: {refund.transactionId}
                          </p>
                          <p className="pl-4">
                            Reason: {refund.reason || 'N/A'}
                          </p>
                          {refund.voidedAt && (
                            <p className="pl-4">
                              VOIDED at: {Moment(refund.voidedAt).format('lll')}
                            </p>
                          )}
                        </div>
                      ))}
                    </FlexContainer>
                  </FlexContainer>
                  <div className="group-menu-items">
                    <p className="group-menu-items-title">Order Items</p>
                    {items.map((o: any) => {
                      return (
                        <p className="group-menu-item" key={o.id}>
                          - {o.quantity}x {o.name}
                        </p>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  renderAuditLogs = () => {
    const {
      isLoadingAuditLogs,
      auditLogs,
      filteredAuditLogs,
      auditLogsId,
      auditLogSearchText,
    } = this.state

    const { groupOrder }: { groupOrder: any } = this.state

    const logs = auditLogSearchText === '' ? auditLogs : filteredAuditLogs

    return (
      <FlexContainer
        justifyContent="space-between"
        flexDirection="column"
        marginTop="20px"
      >
        {groupOrder && isLoadingAuditLogs && auditLogsId !== groupOrder.id && (
          <LoadingIndicator />
        )}
        {groupOrder && auditLogsId === groupOrder.id && (
          <FlexContainer
            flexDirection="column"
            height="1000px"
            overflow="scroll"
            padding="5px"
          >
            <Input
              label="Search Filter"
              marginBottom="10px"
              value={auditLogSearchText}
              onChange={this.onSearchAuditLogs}
            />
            {logs.map((log: any) => {
              return (
                <FlexContainer key={log.id} padding="15px 0px 15px 0px">
                  <AuditLogTable>
                    <tr>
                      <th>Timestamp</th>
                      <th>{log.timestamp.format('MMM D YYYY h:mm:ss a')}</th>
                    </tr>
                    {log.changes.length === 0 && (
                      <td colSpan={2}> * granular change made </td>
                    )}
                    {log.changes.map((change: any, i: number) => (
                      <tr key={i}>
                        <td> {change.key} </td>
                        <td> {change.value} </td>
                      </tr>
                    ))}
                  </AuditLogTable>
                  <YSpacing height="10px" />
                </FlexContainer>
              )
            })}
          </FlexContainer>
        )}
      </FlexContainer>
    )
  }

  renderPaymentsTab = () => {
    const { groupOrder }: { groupOrder: any } = this.state
    const { accountingOrder }: { accountingOrder: any } = this.state
    const { orderPayments = [] } = accountingOrder || {}

    let totalPaid = 0.0
    const invoices: any = {}
    orderPayments.forEach((pmt: any) => {
      if (pmt.isPaid) {
        totalPaid += pmt.amount
      }
      if (pmt.paymentInvoice != null) {
        invoices[pmt.paymentInvoice.invoiceNumber] = true
      }
    })
    const invoicesStr = Object.keys(invoices).join(', ')

    return (
      <FlexContainer
        justifyContent="space-between"
        flexDirection="column"
        marginTop="20px"
      >
        {!accountingOrder ||
          (accountingOrder.id !== groupOrder.id && <p>Loading...</p>)}
        {accountingOrder && accountingOrder.id === groupOrder.id && (
          <FlexContainer
            flexDirection="column"
            height="1000px"
            overflow="scroll"
            padding="5px"
          >
            <FlexContainer padding="15px 0px 15px 0px">
              <AuditLogTable>
                <tr>
                  <th>Total Paid</th>
                  <th>${totalPaid.toFixed(2)}</th>
                  <th />
                  <th />
                </tr>
                <tr>
                  <th>Invoices</th>
                  <th>{invoicesStr}</th>
                  <th />
                  <th />
                </tr>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Paid?</th>
                  <th>Tx</th>
                </tr>
                {orderPayments.map((pmt: any, i: number) => (
                  <tr key={i}>
                    <td>
                      {pmt.paymentTransaction
                        ? Moment(pmt.paymentTransaction.createdAt).format('lll')
                        : Moment(pmt.updatedAt).format('lll')}
                    </td>
                    <td>${pmt.amount.toFixed(2)}</td>
                    <td>{pmt.isPaid ? 'Yes' : 'No'}</td>
                    <td>
                      {(pmt.paymentTransaction && pmt.paymentTransaction.id) ||
                        ''}
                    </td>
                  </tr>
                ))}
              </AuditLogTable>
              <YSpacing height="10px" />
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    )
  }

  render() {
    const {
      searchSalesReps,
      locale,
      clientSettings,
      selectedIndex,
      clientGlobalSettings,
      client: loadedClient,
    } = this.props

    const {
      activeTab,
      dates,
      selectedDate,
      countMap,
      subsidySettingsExpandedMap,
    } = this.state

    const { groupOrder }: { groupOrder: any } = this.state

    if (!loadedClient) {
      return <div>loading...</div>
    }

    const clientSetting = clientSettings[selectedIndex] || {}
    const hasActiveOrders =
      groupOrder && groupOrder.orders.some((o: any) => o.status === 'Active')

    const {
      id,
      name,
      authorizationCode,
      deliveryNotes = '',
      client,
      salesRep,
      active,
      hidePrices,
      deliveryFee,
      deliveryFeeType = GroupOrderDeliveryFeeType.STANDARD,
      deliveryHeadcountLimit,
      location,
      employeeIds,
      tip,
      tipType,
      scheduleSettings,

      useChefNotes,
      usesRacks,
      racksCount,
      overflowRacks,
      shelvesPerRack,
      overflowName,
    } = clientSetting

    const { subsidySettings: subsidies } = clientSetting || {}

    let subsidySettings
    if (groupOrder) {
      ;({ subsidySettings } = groupOrder)
    } else {
      const subsidySettingsMap = (
        (subsidies && subsidies.subsidies) ||
        []
      ).reduce((map: any, subsidySetting: any) => {
        if (Array.isArray(subsidySetting.days)) {
          if (subsidySetting.isActive) {
            subsidySetting.days.forEach((d: any) => {
              map[d.toString()] = subsidySetting
            })
          }
        }

        return map
      }, {})

      subsidySettings =
        (selectedDate && subsidySettingsMap[selectedDate.day()]) || {}
    }
    const {
      fullySubsidized,
      itemPriceSettings,
      limitPurchase,
      perOrderSubsidy,
      subsidyPercentLimitAmt,
      isSubsidyPercentLimit,
      subsidyType,
    } = subsidySettings || {}

    return (
      <FlexContainer justifyContent="space-between">
        <Panel width="48%" maxWidth="800px" heading="Client Details">
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              width="48%"
              label="Client"
              marginBottom="20px"
              value={client && client.name}
            />
            <LabelInfo
              width="48%"
              label="Global Auth Code"
              marginBottom="20px"
              value={client && `GRP-${client.pin}`}
            />
          </FlexContainer>

          <FlexContainer justifyContent="space-between">
            <LabelInfo
              width="80%"
              label="Logo"
              marginBottom="20px"
              value={(client && client.logoUrl) || 'None'}
            />
          </FlexContainer>
          <DividerLine margin="10px 0" />
          <p>
            <span className="bold">{'CLIENT GLOBAL SETTINGS'}</span>
          </p>
          <ClientGlobalSettings
            clientGlobalSettings={clientGlobalSettings}
            clientSettingsCollection={clientSettings}
            clientName={this.props.client ? this.props.client.name : ''}
            clientId={this.props.client ? this.props.client.id : ''}
            onChangeClientGlobalSettings={this.onChangeClientGlobalSettings}
            onSaveClientGlobalSettings={this.props.onSaveClientGlobalSettings}
            onPrepareGroupOrderInvoice={this.onPrepareGroupOrderInvoice}
            onSearchGroupOrders={this.onSearchGroupOrders}
            toggleDeliveryFeeSettingsModal={
              this.props.toggleDeliveryFeeSettingsModal
            }
            toggleStaffingFeeSettingsModal={
              this.props.toggleStaffingFeeSettingsModal
            }
          />
          <DividerLine margin="20px 0" />
          {clientSettings[selectedIndex] &&
            clientSettings[selectedIndex].id && (
              <>
                <Button
                  label={'Add New Location'}
                  onClick={this.onAddNewClientSettings}
                />
                <YSpacing height="20px" />
              </>
            )}
          <Input
            label="Location Name"
            placeholder={client ? `${client.name} (default)` : ''}
            marginBottom="20px"
            value={name}
            onChange={(e) =>
              this.onChangeClientSettings('name', e.target.value)
            }
          />

          <FlexContainer justifyContent="space-between">
            <LabelInfo
              width="48%"
              label="Auth Code"
              marginBottom="20px"
              value={id ? authorizationCode : 'Generated on creation...'}
            />
            <LabelInfo
              width="48%"
              label="# of Employees"
              marginBottom="20px"
              value={id ? employeeIds.length : 0}
            />
          </FlexContainer>

          <FlexContainer justifyContent="space-between">
            <AutocompleteInput
              label="Search Sales Rep"
              displayAttribute="name"
              width="48%"
              loaderFunction={(search) => searchSalesReps({ ...search })}
              value={
                salesRep ? `${salesRep.firstName} ${salesRep.lastName}` : ''
              }
              onSelect={(salesRep) =>
                this.onChangeClientSettings('salesRep', salesRep)
              }
            />

            {client && loadedClient && (
              <Dropdown
                label="contact"
                width="48%"
                value={client.contact ? client.contact.id : ''}
                onChange={(e) => this.onChangeContact(e.target.value)}
              >
                <option />
                {(loadedClient.contacts || []).map((ct: any) => (
                  <option key={ct.id} value={ct.id}>
                    {ct.name}
                  </option>
                ))}
              </Dropdown>
            )}
          </FlexContainer>
          <Dropdown
            label="Dropoff Address"
            value={location ? location.id : ''}
            onChange={(e) => this.onChangeLocation(e.target.value)}
          >
            <option />
            {((loadedClient && loadedClient.addresses) || []).map(
              (addr: any) => (
                <option key={addr.id} value={addr.id}>
                  {addr.fullAddress}
                </option>
              ),
            )}
          </Dropdown>

          {location && (
            <Input
              label="Pickup Location"
              marginBottom="20px"
              value={location.spot}
              onChange={(e) =>
                this.onChangeClientSettings('location', {
                  ...location,
                  spot: e.target.value,
                })
              }
            />
          )}

          <Checkbox
            label="Uses Chef Notes?"
            value={useChefNotes}
            checked={useChefNotes}
            onChange={(e: any) =>
              this.onChangeClientSettings('useChefNotes', e.target.checked)
            }
          />
          <YSpacing height="10px" />

          <FlexContainer justifyContent="space-between" flexDirection="column">
            <Checkbox
              label="Uses Racks?"
              value={usesRacks}
              checked={usesRacks}
              onChange={(e: any) =>
                this.onChangeClientSettings('usesRacks', e.target.checked)
              }
            />
            <YSpacing height="10px" />

            {usesRacks && (
              <FlexContainer
                justifyContent="space-between"
                flexDirection="column"
              >
                <FlexContainer
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Input
                    width="30%"
                    label="On-Site Racks"
                    value={racksCount}
                    type="number"
                    onChange={(e) =>
                      this.onChangeClientSettings(
                        'racksCount',
                        parseInt(e.target.value),
                      )
                    }
                  />
                  <Input
                    width="30%"
                    label="Overflow Racks"
                    value={overflowRacks}
                    type="number"
                    onChange={(e) =>
                      this.onChangeClientSettings(
                        'overflowRacks',
                        parseInt(e.target.value),
                      )
                    }
                  />
                  <Input
                    width="30%"
                    label="Shelves per Rack"
                    value={shelvesPerRack}
                    type="number"
                    onChange={(e) =>
                      this.onChangeClientSettings(
                        'shelvesPerRack',
                        parseInt(e.target.value),
                      )
                    }
                  />
                </FlexContainer>

                <YSpacing height="5px" />

                <Input
                  width="80%"
                  label="Overflow Shelf / Area Name"
                  value={overflowName}
                  type="text"
                  onChange={(e) =>
                    this.onChangeClientSettings('overflowName', e.target.value)
                  }
                />
              </FlexContainer>
            )}
          </FlexContainer>

          <FlexContainer justifyContent="space-between">
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'sales rep']}
            >
              <Dropdown
                label="Delivery Fee Type"
                width="48%"
                value={deliveryFeeType}
                onChange={(e) =>
                  this.onChangeClientSettings('deliveryFeeType', e.target.value)
                }
              >
                {DELIVERY_FEE_OPTIONS.map((type) => (
                  <option key={type} value={type}>
                    {GetDeliveryTypeDisplayName(type)}
                  </option>
                ))}
              </Dropdown>
            </AuthorizedInteractable>
            {DELIVERY_FEE_TYPES_REQUIRING_VALUE.includes(deliveryFeeType) && (
              <AuthorizedInteractable
                roles={['master admin', 'sales lead', 'sales rep']}
              >
                <Input
                  width="48%"
                  label={
                    deliveryFeeType === GroupOrderDeliveryFeeType.PERCENT
                      ? 'value (%)'
                      : 'value ($)'
                  }
                  value={deliveryFee}
                  type="number"
                  onChange={(e) =>
                    this.onChangeClientSettings(
                      'deliveryFee',
                      parseFloat(e.target.value),
                    )
                  }
                />
              </AuthorizedInteractable>
            )}
          </FlexContainer>
          {deliveryFeeType == 'UnderLimit' && (
            <FlexContainer justifyContent="flex-end">
              <AuthorizedInteractable
                roles={['master admin', 'sales lead', 'sales rep']}
              >
                <Input
                  width="48%"
                  label={'headcount limit'}
                  value={deliveryHeadcountLimit}
                  type="number"
                  onChange={(e) =>
                    this.onChangeClientSettings(
                      'deliveryHeadcountLimit',
                      parseInt(e.target.value),
                    )
                  }
                />
              </AuthorizedInteractable>
            </FlexContainer>
          )}
          <p>
            <span className="bold">{'SUBSIDY SETTINGS'}</span>
          </p>
          <Button
            label={'Add Subsidy Settings'}
            onClick={this.onAddNewSubsidySettings}
            style={{ width: 20 }}
          />
          <YSpacing height="10px" />
          <DividerLine margin="10px 0" />
          {((subsidies && subsidies.subsidies) || []).map(
            (subsidy: any, subsidyIndex: any) => {
              return (
                <FlexContainer
                  key={subsidyIndex}
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <SubsidySettings
                    subsidy={subsidy}
                    index={subsidyIndex}
                    isExpanded={
                      (subsidySettingsExpandedMap as any)[subsidyIndex]
                    }
                    onChangeSubsidySettings={this.onChangeSubsidySettings}
                    onRemoveSubsidySettings={this.onRemoveSubsidySettings}
                    onToggleSubsidySettings={this.onToggleSubsidySettings}
                  />
                  <YSpacing height="10px" />
                  {subsidyIndex !== subsidies.subsidies.length - 1 ? (
                    <DividerLine
                      height="5px"
                      margin="10px 0"
                      background="#ef5d51"
                    />
                  ) : (
                    <DividerLine margin="10px 0" />
                  )}
                </FlexContainer>
              )
            },
          )}
          <FlexContainer justifyContent="space-between">
            <Dropdown
              label="Tip Type"
              width="48%"
              value={tipType}
              onChange={(e) =>
                this.onChangeClientSettings('tipType', e.target.value)
              }
            >
              {['FixedTotal', 'FixedPerOrder', 'Percent'].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Dropdown>
            <Input
              width="48%"
              label={
                ['FixedTotal', 'FixedPerOrder'].includes(tipType)
                  ? 'value ($)'
                  : 'value (%)'
              }
              value={tip}
              type="number"
              onChange={(e) =>
                this.onChangeClientSettings('tip', parseInt(e.target.value))
              }
            />
          </FlexContainer>
          <YSpacing height="10px" />
          <Checkbox
            label="Active?"
            value={active}
            checked={active}
            onChange={(e: any) =>
              this.onChangeClientSettings('active', e.target.checked)
            }
          />
          <YSpacing height="10px" />
          <Checkbox
            label="Hide Prices?"
            value={hidePrices}
            checked={hidePrices}
            onChange={(e: any) =>
              this.onChangeClientSettings('hidePrices', e.target.checked)
            }
          />
          <YSpacing height="20px" />
          <TextArea
            label="Delivery Notes"
            marginBottom="20px"
            value={deliveryNotes}
            onChange={(e: any) =>
              this.onChangeClientSettings('deliveryNotes', e.target.value)
            }
          />
          <Button
            label={id ? 'Save Changes' : 'Create Settings'}
            onClick={() => this.props.onSaveClientSettings()}
          />
        </Panel>
        {/* Group Orders For Date */}
        <Panel width="48%" maxWidth="800px" heading="Edit Menus & See Orders">
          {!id && <p>Create client settings to view menus</p>}
          {id && (
            <div>
              <div className="panel-legend">
                <span style={{ background: '#50e3c2' }} />
                <p>Has menus and/or orders</p>
              </div>
              <Flatpickr
                options={{
                  altInput: true,
                  altInputClass: 'hidden',
                  inline: true,
                  formatDate: (date: any) =>
                    Moment(date).tz(locale).format(DateFormat),
                }}
                className="panel-calendar"
                value={selectedDate.toDate()}
                onDayCreate={(dObj: any, dStr: any, fp: any, dayElem: any) => {
                  // flatpickr aria-label not rendering correct date for west coast need to replace date with rendered element content
                  const dateStr = dayElem
                    .getAttribute('aria-label')
                    .replace(
                      /(?<month>[a-zA-Z]+) (?<date>\d+), (?<year>\d+)/,
                      (match: any, month: any, date: any, year: any) =>
                        `${month} ${dayElem.textContent}, ${year}`,
                    )
                  if ((dates as any)[dateStr]) {
                    dayElem.classList.add('highlighted')
                  }
                }}
                onChange={(result: any) => {
                  const date: any = result[0]
                  // format js date to noon moment date
                  const selectedDate = Moment.tz(
                    `${date.getFullYear()}-${
                      date.getMonth() + 1
                    }-${date.getDate()} 12`,
                    'YYYY-MM-DD HH',
                    locale,
                  )
                  this.onSelectDate(selectedDate)
                }}
              />
              <DividerLine margin="20px 0" />
              <p>
                Edit menus & view orders for{' '}
                <span className="bold">
                  {Moment(selectedDate).format('ddd, LL')}
                </span>
              </p>
              <YSpacing height="10px" />
              <FlexContainer>
                <p
                  onClick={() => this.setState({ activeTab: MENU_TAB })}
                  className={`${
                    activeTab === MENU_TAB ? 'panel-tab-active' : 'panel-tab'
                  }`}
                >
                  Menus
                </p>
                <p
                  onClick={() => this.setState({ activeTab: ORDERS_TAB })}
                  className={`${
                    activeTab === ORDERS_TAB ? 'panel-tab-active' : 'panel-tab'
                  }`}
                >
                  Orders
                </p>
                <p
                  onClick={() => this.setState({ activeTab: RACKS_TAB })}
                  className={`${
                    activeTab === RACKS_TAB ? 'panel-tab-active' : 'panel-tab'
                  }`}
                >
                  Racks
                </p>
                <p
                  onClick={this.onToggleAuditLogsTab}
                  className={`${
                    activeTab === AUDIT_LOGS_TAB
                      ? 'panel-tab-active'
                      : 'panel-tab'
                  }`}
                >
                  Audits
                </p>
                <p
                  onClick={this.onTogglePaymentsTab}
                  className={`${
                    activeTab === PAYMENTS_TAB
                      ? 'panel-tab-active'
                      : 'panel-tab'
                  }`}
                >
                  Payments
                </p>
              </FlexContainer>

              {activeTab === MENU_TAB && id && selectedDate && (
                <DateMenus
                  date={selectedDate}
                  clientSettingsId={id}
                  clientSettings={clientSettings[selectedIndex] || null}
                  clientId={client.id}
                  groupOrder={groupOrder || null}
                  hasActiveOrders={hasActiveOrders}
                  perOrderSubsidy={parseFloat(perOrderSubsidy)}
                  subsidyPercentLimitAmt={parseFloat(subsidyPercentLimitAmt)}
                  validateMenus={limitPurchase && !fullySubsidized}
                  countMap={countMap}
                  subsidyType={subsidyType}
                  scheduleSettings={scheduleSettings}
                  isSubsidyPercentLimit={isSubsidyPercentLimit}
                  onReloadDates={() => this.onReloadDates()}
                  itemPriceSettings={itemPriceSettings}
                />
              )}
              {activeTab === ORDERS_TAB && this.renderOrderDetails()}
              {activeTab === RACKS_TAB && this.renderRackDetails()}
              {activeTab === AUDIT_LOGS_TAB && this.renderAuditLogs()}
              {activeTab === PAYMENTS_TAB && this.renderPaymentsTab()}
            </div>
          )}
        </Panel>
      </FlexContainer>
    )
  }
}

const AuditLogTable = styled.table`
  width: 100%;
  border-radius: 10px;
  background: ${colors.gray200};
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'bold';
    color: ${colors.gray400};
    padding: 5px 10px;
    background: #eff1f6;
    white-space: nowrap;
  }
  tr {
    border-bottom: 1px solid ${colors.gray200};
  }
  td {
    color: ${colors.gray400};
    vertical-align: middle;
    padding: 5px 10px;
    font-family: 'regular';
  }
  .right {
    text-align: right;
  }
`

export default GroupOrdersPage
