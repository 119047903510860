import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'
import Panel from '@components/common/Panel'
import {
  DateTextInput,
  LinkText,
  MarketDropdown,
} from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import LoadingIndicator from '@components/common/LoadingIndicator'

const DateFormat = 'MM/DD/YYYY h:mm a'

class GroupOrderReviewsReportsPage extends Component {
  state = {
    itemReviewsCSVStart: Moment.tz(this.props.locale),
    itemReviewsCSVEnd: Moment.tz(this.props.locale),
    numberOfItemReviews: 0,
    client: { id: null },
    chefId: null,
    menuItemId: null,
    isLoading: false,
    menuItemSearchParams: {
      chefIds: [],
      marketTypes: ['Group Order'],
      isApproved: 'true',
      chefStatus: 'active',
    },
    headquarters: [],
    selectedHeadquarters: [],
  }

  componentDidMount = async () => {
    const hqs = await this.props.loadHeadquarters()
    this.setState({
      headquarters: [{ id: 0, name: 'All' }, ...hqs],
    })
  }

  searchGroupOrderItemReviews = async () => {
    const {
      itemReviewsCSVStart: itemReviewsCSVStart,
      itemReviewsCSVEnd: itemReviewsCSVEnd,
      client,
      chefId,
      menuItemId,
      selectedHeadquarters,
    } = this.state

    this.setState({ isLoading: true })

    const numberOfItemReviews = await this.props.searchGroupOrderReviewsCount({
      start: itemReviewsCSVStart,
      end: itemReviewsCSVEnd,
      clientId: client.id,
      chefId,
      menuItemId,
      hqIds: selectedHeadquarters,
    })

    if (typeof numberOfItemReviews === 'number') {
      this.setState({ numberOfItemReviews })
    }

    this.setState({ isLoading: false })
  }

  handleRequestSendReport = async () => {
    const { currentUser } = this.props

    const {
      itemReviewsCSVStart,
      itemReviewsCSVEnd,
      client,
      chefId,
      menuItemId,
      selectedHeadquarters,
    } = this.state

    const params = {
      start: itemReviewsCSVStart,
      end: itemReviewsCSVEnd,
      clientId: client.id,
      chefId,
      menuItemId,
      email: currentUser.email,
      hqIds: selectedHeadquarters,
    }

    await this.props.requestSendGroupOrderReviewsReport(params)
  }

  onSelectClient = (client) => {
    this.setState({ client, numberOfItemReviews: 0 })
  }

  onSelectChef = (chef) => {
    const { menuItemSearchParams } = this.state
    this.setState({
      chefId: chef.id,
      menuItemSearchParams: {
        ...menuItemSearchParams,
        chefIds: [chef.id],
      },
      numberOfItemReviews: 0,
    })
  }

  onSelectMenuItem = (menuItem) => {
    this.setState({ menuItemId: menuItem.id, numberOfItemReviews: 0 })
  }

  onSelectHq = (hqId) => {
    const { headquarters, selectedHeadquarters } = this.state
    if (hqId === 0) {
      this.setState({
        selectedHeadquarters: headquarters
          .filter((h) => h.id !== 0)
          .map((h) => h.id),
      })
    } else if (!selectedHeadquarters.includes(hqId)) {
      this.setState({
        selectedHeadquarters: [...selectedHeadquarters, hqId],
      })
    }
  }

  onDeselectHq = (hqId) => {
    const { selectedHeadquarters } = this.state
    if (hqId === 0) {
      this.setState({
        selectedHeadquarters: [],
      })
    } else {
      this.setState({
        selectedHeadquarters: selectedHeadquarters.filter((h) => h !== hqId),
      })
    }
  }

  render() {
    const { locale, headquarter } = this.props
    const {
      isLoading,
      itemReviewsCSVStart,
      itemReviewsCSVEnd,
      numberOfItemReviews,
      menuItemSearchParams,
      headquarters,
      selectedHeadquarters,
    } = this.state

    return (
      <Panel width="100%" maxWidth="500px" heading="Item Reviews Report">
        <YSpacing height="20px" />
        <MarketDropdown
          label="Market(s)"
          onSelectHq={this.onSelectHq}
          onDeselectHq={this.onDeselectHq}
          options={headquarters}
          selectedOptions={selectedHeadquarters}
        />
        <YSpacing height="20px" />
        <DateTextInput
          label="Start Time"
          date={itemReviewsCSVStart}
          onChange={(date) =>
            this.setState({ itemReviewsCSVStart: date, numberOfItemReviews: 0 })
          }
          dateFormat={DateFormat}
          timeZone={locale}
        />
        <YSpacing height="20px" />
        <DateTextInput
          label="End Time"
          date={itemReviewsCSVEnd}
          onChange={(date) =>
            this.setState({ itemReviewsCSVEnd: date, numberOfItemReviews: 0 })
          }
          dateFormat={DateFormat}
          timeZone={locale}
        />
        <YSpacing height="20px" />
        <AutocompleteInput
          displayAttribute="name"
          loaderFunction={(search) =>
            this.props.searchGroupOrdersClients({ ...search, headquarter })
          }
          placeholder={'Search Clients'}
          onSelect={this.onSelectClient}
        />
        <YSpacing height="20px" />
        <AutocompleteInput
          displayAttribute="name"
          loaderFunction={(search) => this.props.searchChefs({ ...search })}
          placeholder={'Search Chefs'}
          onSelect={this.onSelectChef}
        />
        <YSpacing height="20px" />
        <AutocompleteInput
          displayAttribute="name"
          loaderFunction={(search) =>
            this.props.searchMenuItems({ ...menuItemSearchParams, ...search })
          }
          placeholder={'Search Menu Items'}
          onSelect={this.onSelectMenuItem}
        />

        <YSpacing height="20px" />
        <span className="mr-4">
          Found&nbsp;
          {`${numberOfItemReviews} review(s)`}&nbsp;
        </span>

        <YSpacing height="20px" />
        {isLoading ? <LoadingIndicator /> : null}
        {!this.state.isLoading && this.state.numberOfItemReviews > 0 && (
          <LinkText
            label={`Download CSV`}
            onClick={this.handleRequestSendReport}
          />
        )}
        <YSpacing height="10px" />
        <Button
          onClick={this.searchGroupOrderItemReviews}
          disabled={isLoading}
          label={isLoading ? 'Searching...' : 'Search'}
        />
      </Panel>
    )
  }
}

GroupOrderReviewsReportsPage.propTypes = {
  locale: PropTypes.string,
  headquarter: PropTypes.number,
  currentUser: PropTypes.object,

  searchGroupOrderReviewsCount: PropTypes.func,
  requestSendGroupOrderReviewsReport: PropTypes.func,
  searchGroupOrdersClients: PropTypes.func,
  searchChefs: PropTypes.func,
  searchMenuItems: PropTypes.func,
  loadHeadquarters: PropTypes.func,
}

export default GroupOrderReviewsReportsPage
