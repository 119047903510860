import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from '@components/common/Loader'

class TextSelectInput extends Component {
  state = {
    isFocused: false,
    showDropdown: false,
    value: '',
  }

  componentDidMount() {
    window.addEventListener('click', this.collapse)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.collapse)
  }

  componentWillReceiveProps(nextProps) {
    const { options, value } = nextProps
    if (options !== this.props.options) {
      this.optionMap = {}
      options.forEach((o) => (this.optionMap[o.id] = o))
    }
    if (value !== this.props.value) {
      this.setState({ value })
    }
  }

  collapse = () => {
    this.setState({ showDropdown: false })
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onChange = (e) => {
    const { options } = this.props
    if (options.length === 0) {
      return
    }

    let code = 0
    if (e.keyCode) {
      code = e.keyCode
    } else if (e.which) {
      code = e.which
    }

    // choose first option if tab or enter
    if (code === 9 || code === 13) {
      this.onSelect(options[0])
    }
  }

  onFocus = (e) => {
    e.stopPropagation()

    const { onFocus } = this.props

    this.setState({ isFocused: true, showDropdown: true })
    onFocus && onFocus()
  }

  onInput = (e) => {
    const { onInput } = this.props
    const { value } = e.target

    this.setState({ value })

    onInput && onInput(value)
  }

  onSelect = (e, optionId) => {
    e.stopPropagation()

    const { onSelect } = this.props
    const option = this.optionMap[optionId]

    this.setState({
      showDropdown: false,
      value: option.name,
    })

    onSelect && onSelect(option)
  }

  render() {
    const { isFocused, showDropdown, value } = this.state
    const {
      optionsHeight,
      displayAttribute,
      error,
      label,
      options,
      placeholder,
      showLoader,
    } = this.props

    return (
      <div className="relative w-full">
        {error && !isFocused && (
          <p className="text-red-700 bold text-base">{error}</p>
        )}
        <input
          type="text"
          label={label}
          name={label}
          value={value}
          className="w-full"
          placeholder={placeholder}
          onBlur={this.onBlur}
          onClick={(e) => e.stopPropagation()}
          onFocus={this.onFocus}
          onInput={this.onInput}
        />
        {showDropdown && (
          <div
            className={`absolute w-100 z-10 border overflow-y-scroll h-${optionsHeight} bg-white border-gray-400 shadow-lg`}
          >
            {showLoader && <Loader isCenter={true} />}
            {options.map((o) => (
              <p
                className="cursor-pointer p-2 hover:bg-gray-200 regular text-lg text-hungryGray"
                key={o.id}
                id={o.name ? o.name : o.id}
                onClick={(e) => this.onSelect(e, o.id)}
              >
                {o[displayAttribute]}
              </p>
            ))}
          </div>
        )}
      </div>
    )
  }
}

TextSelectInput.propTypes = {
  displayAttribute: PropTypes.string,
  optionsHeight: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showLoader: PropTypes.bool,
  error: PropTypes.string,

  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  onSelect: PropTypes.func,
}

TextSelectInput.defaultProps = {
  optionsHeight: '40',
  displayAttribute: 'name',
  options: [],
  placeholder: '',
}

export default TextSelectInput
