import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CopyMenuItemModal } from '@containers/chef/edit'
import { EditAccountModal } from '@containers/account/edit'
import { EditAccountContactModal } from '@containers/account/edit'
import { EditBatchOrderModal } from '@containers/batchOrder/edit'
import { EditChefModal } from '@containers/chef/edit'
import {
  EditChildChefsModal,
  CopyMenuItemsModal,
  ChefFutureOrdersModal,
} from '@containers/chef/chefSections'
import { EditChefContactModal, CalcChefFeesModal } from '@containers/chef/edit'
import { EditChefPayoutsModal } from '@containers/order/edit'
import { EditChildMenuItemModal } from '@containers/chef/edit'
import { EditDeliveryZoneModal } from '@containers/market/edit'
import { EditMenuConceptModal } from '@containers/chef/edit'
import { EditHQModal } from '@containers/market/edit'
import { EditMenuItemModal } from '@containers/chef/edit'
import { EditOrderModal } from '@containers/order/edit'
import { EditRecipeModal } from '@containers/chef/edit'
import { EditProposalModal } from '@containers/proposal/edit'
import { EditServiceDepotModal } from '@containers/market/edit'
import { EditServiceItemModal } from '@containers/chef/edit'
import { EditSnackPackModal } from '@containers/chef/edit'
import { EditVirtualItemModal } from '@containers/chef/edit'
import { EditVirtualKitModal } from '@containers/chef/edit'
import { EditUserModal } from '@containers/user/edit'
import {
  AuditHistoryModal,
  ResultsMessageModal,
} from '@containers/common/modal'

class Modals extends Component {
  render() {
    const {
      showCalcChefFeesModal,
      showCopyMenuItem,
      showAccount,
      showAccountContact,
      showAuditHistory,
      showBatchOrder,
      showChef,
      showChefContact,
      showChefPayouts,
      showChildMenuItem,
      showConcepts,
      showDeliveryZone,
      showHQ,
      showMenuItem,
      showOrder,
      showRecipe,
      showProposal,
      showServiceDepot,
      showServiceItem,
      showSnackPack,
      showVirtualItem,
      showVirtualKit,
      showUser,
      showEditChildChefs,
      showCopyMenuItems,
      showChefFutureOrders,
      showResultsMessageModal,
    } = this.props

    return (
      <div>
        {showCopyMenuItem && <CopyMenuItemModal />}
        {showAccount && <EditAccountModal />}
        {showAccountContact && <EditAccountContactModal />}
        {showBatchOrder && <EditBatchOrderModal />}
        {showChefPayouts && <EditChefPayoutsModal />}
        {showChef && <EditChefModal />}
        {showChefContact && <EditChefContactModal />}
        {showChildMenuItem && <EditChildMenuItemModal />}
        {showChefFutureOrders && <ChefFutureOrdersModal />}
        {showConcepts && <EditMenuConceptModal />}
        {showDeliveryZone && <EditDeliveryZoneModal />}
        {showHQ && <EditHQModal />}
        {showMenuItem && <EditMenuItemModal />}
        {showOrder && <EditOrderModal />}
        {showRecipe && <EditRecipeModal />}
        {showProposal && <EditProposalModal />}
        {showResultsMessageModal && <ResultsMessageModal />}
        {showServiceDepot && <EditServiceDepotModal />}
        {showServiceItem && <EditServiceItemModal />}
        {showSnackPack && <EditSnackPackModal />}
        {showVirtualItem && <EditVirtualItemModal />}
        {showVirtualKit && <EditVirtualKitModal />}
        {showUser && <EditUserModal />}
        {showEditChildChefs && <EditChildChefsModal />}
        {showCopyMenuItems && <CopyMenuItemsModal />}
        {showCalcChefFeesModal && <CalcChefFeesModal />}
        {showAuditHistory && <AuditHistoryModal />}
      </div>
    )
  }
}

Modals.propTypes = {
  showCalcChefFeesModal: PropTypes.bool,
  showCopyMenuItem: PropTypes.bool,
  showAccount: PropTypes.bool,
  showAccountContact: PropTypes.bool,
  showAuditHistory: PropTypes.bool,
  showBatchOrder: PropTypes.bool,
  showChef: PropTypes.bool,
  showChefContact: PropTypes.bool,
  showChefPayouts: PropTypes.bool,
  showChildMenuItem: PropTypes.bool,
  showConcepts: PropTypes.bool,
  showDeliveryZone: PropTypes.bool,
  showHQ: PropTypes.bool,
  showMenuItem: PropTypes.bool,
  showOrder: PropTypes.bool,
  showRecipe: PropTypes.bool,
  showProposal: PropTypes.bool,
  showServiceDepot: PropTypes.bool,
  showServiceItem: PropTypes.bool,
  showSnackPack: PropTypes.bool,
  showVirtualItem: PropTypes.bool,
  showVirtualKit: PropTypes.bool,
  showUser: PropTypes.bool,
  showEditChildChefs: PropTypes.bool,
  showCopyMenuItems: PropTypes.bool,
  showChefFutureOrders: PropTypes.bool,
  showResultsMessageModal: PropTypes.bool,
}

export default Modals
