import React, { useState, useEffect } from 'react'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '@constants'
import { Dropdown, DateInput, Button, LabelInfo } from '@components/common/form'
import DateTextInput from '@components/common/form/DateTextInput'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import { RECURRING } from '../../../../constants'

const ScheduleEditModal = (props) => {
  const [timeSlot, setTimeSlot] = useState(props.timeSlot)
  const [hasRecurringEndDate, setHasRecurringEndDate] = useState(false)

  useEffect(() => {
    const newSlot = props.timeSlot
    setTimeSlot(newSlot)
    setHasRecurringEndDate(newSlot.endDate ? true : false)
  }, [props])

  const repeatTypes = [
    { key: false, value: 'Does not repeat' },
    { key: true, value: 'Yes, select days' },
  ]
  const weekDays = [
    { key: 1, value: 'Mon' },
    { key: 2, value: 'Tue' },
    { key: 3, value: 'Wed' },
    { key: 4, value: 'Thur' },
    { key: 5, value: 'Fri' },
    { key: 6, value: 'Sat' },
    { key: 7, value: 'Sun' },
  ]

  const onSelectCustomDay = (day) => {
    const days = [...timeSlot.recurringDaysOfWeek]
    const index = days.indexOf(day.key)
    if (index === -1) {
      days.push(day.key)
    } else {
      days.splice(index, 1)
    }
    setTimeSlot({ ...timeSlot, recurringDaysOfWeek: days })
  }

  const onChangeRecurringEnds = (e) => {
    const { value } = e.target
    setHasRecurringEndDate(value === 'true' ? true : false)
    if (!value) {
      setTimeSlot({ ...timeSlot, endDate: null })
    }
  }

  const onSaveSchedule = async () => {
    const { displayFailureMessage } = props
    if (hasRecurringEndDate && !timeSlot.endDate) {
      displayFailureMessage(
        'Schedule is configured to have repeat end, but does not have an end date set.',
      )

      return
    }

    const slot = Object.assign({}, timeSlot)
    const { end, start, calendarDate } = slot
    const momentEnd = Moment(end)
    const momentStart = Moment(start)
    const endFloat = momentEnd.hour() + momentEnd.minute() / 60
    const startFloat = momentStart.hour() + momentStart.minute() / 60
    slot.date = new Date(calendarDate)
    slot.end = endFloat
    slot.start = startFloat
    if (!slot.recurring) {
      slot.day = calendarDate.isoWeekday()
    }
    slot.month = calendarDate.format('MM/YYYY')
    slot.week = slot.recurring
      ? RECURRING
      : calendarDate.startOf('isoWeek').format('MM/DD/YYYY')
    slot.recurringEnds = hasRecurringEndDate ? true : false
    slot.endDate = hasRecurringEndDate ? slot.endDate : null

    await props.updateChefSchedule({
      timeSlot: slot,
      chefId: props.chefId,
      monthDate: props.month,
      closeModal: props.hideModal,
    })
  }

  const onDeleteDaySchedule = (calendarDate, closeModal) => {
    const isoDay = calendarDate.isoWeekday()
    const params = {
      calendarDate,
      slotId: timeSlot.id,
      month: calendarDate.format('MM/YYYY'),
      week: calendarDate.startOf('isoWeek').format('MM/DD/YYYY'),
      day: isoDay,
    }

    props.deleteDayFromRecurringSched(params, closeModal, props.month)
  }

  const renderDeleteButtons = () => {
    const month = timeSlot.calendarDate.format('MM/YYYY')
    const { id, calendarDate } = timeSlot
    const isoDay = calendarDate.isoWeekday()
    if (!timeSlot.recurring) {
      return (
        <Button
          backgroundColor={colors.violet}
          margin="10px 0 0 0"
          width="100%"
          label="Delete This Schedule"
          onClick={() =>
            props.deleteChefSchedule({
              slotId: id,
              date: new Date(calendarDate),
              month,
              closeModal: props.hideModal,
              monthDate: props.month,
              week: calendarDate.startOf('isoWeek').format('MM/DD/YYYY'),
              day: isoDay,
            })
          }
        />
      )
    } else {
      return (
        <div>
          <Button
            backgroundColor={colors.violet}
            margin="10px 0 0 0"
            width="100%"
            label={`Delete schedule for ${timeSlot.calendarDate.format(
              'dddd, MMMM D, YYYY',
            )}`}
            onClick={() =>
              onDeleteDaySchedule(timeSlot.calendarDate, props.hideModal)
            }
          />
          <Button
            backgroundColor={colors.violet}
            margin="10px 0 0 0"
            width="100%"
            label="Delete This Recurring Schedule"
            onClick={() =>
              props.deleteChefSchedule({
                slotId: timeSlot.id,
                date: new Date(timeSlot.calendarDate),
                week: RECURRING,
                month,
                closeModal: props.hideModal,
                monthDate: props.month,
              })
            }
          />
        </div>
      )
    }
  }

  return (
    <Modal
      title="Edit Schedule"
      hideModal={props.hideModal}
      color="#001940"
      width="500px"
    >
      <DateTitle>
        {timeSlot.calendarDate.format('dddd, MMMM D, YYYY')}
      </DateTitle>
      <FlexContainer>
        <DateTextInput
          dateFormat="LT"
          date={timeSlot.start}
          width="31%"
          label="From Time"
          onChange={(time) => setTimeSlot({ ...timeSlot, start: Moment(time) })}
          timeZone={props.locale}
        />
        <XSpacing width="20px" />
        <DateTextInput
          dateFormat="LT"
          date={timeSlot.end}
          width="31%"
          label="To Time"
          onChange={(time) => setTimeSlot({ ...timeSlot, end: Moment(time) })}
          timeZone={props.locale}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <Dropdown
        label="Repeat Schedule?"
        onChange={(e) =>
          setTimeSlot({ ...timeSlot, recurring: e.target.value === 'true' })
        }
        value={timeSlot.recurring}
      >
        {repeatTypes.map((option) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </Dropdown>
      {timeSlot.recurring && (
        <div>
          <LabelInfo label="Select Days to Repeat" />
          <FlexContainer>
            {weekDays.map((day) => (
              <Day
                selected={timeSlot.recurringDaysOfWeek.find(
                  (d) => d === day.key,
                )}
                key={day.key}
                onClick={() => onSelectCustomDay(day)}
                value={day.key}
              >
                {day.value}
              </Day>
            ))}
          </FlexContainer>
          <YSpacing height="20px" />
          <Dropdown
            label="repeat ends"
            marginBottom="10px"
            onChange={onChangeRecurringEnds}
            value={hasRecurringEndDate}
          >
            <option key="never" value={false}>
              Never
            </option>
            <option key="hasEnd" value={true}>
              Yes (select date)
            </option>
          </Dropdown>
        </div>
      )}
      {timeSlot.recurring && hasRecurringEndDate && (
        <DateInput
          date={timeSlot.endDate ? Moment(timeSlot.endDate) : undefined}
          onChange={(date) => setTimeSlot({ ...timeSlot, endDate: date })}
          clearDate={() => setTimeSlot({ ...timeSlot, endDate: null })}
        />
      )}

      {timeSlot.id && renderDeleteButtons()}
      <Button
        margin="20px 0 0 0"
        width="100%"
        label={timeSlot.id ? 'Save Edits' : 'Save Schedule'}
        onClick={onSaveSchedule}
      />
    </Modal>
  )
}

const Day = styled.button`
  font-family: 'regular';
  background-color: ${(props) =>
    props.selected ? colors.orange : colors.gray100};
  color: ${(props) => (props.selected ? 'white' : colors.gray300)};
  padding: 5px;
  width: 100px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 4px;
`

const DateTitle = styled.h1`
  font-size: 18px;
  color: ${colors.gray400};
  font-family: 'bold';
  margin-bottom: 20px;
`

ScheduleEditModal.propTypes = {
  authorized: PropTypes.bool,
  chefId: PropTypes.string,
  locale: PropTypes.string,
  month: PropTypes.object,
  timeSlot: PropTypes.object,

  deleteChefSchedule: PropTypes.func,
  deleteDayFromRecurringSched: PropTypes.func,
  hideModal: PropTypes.func,
  updateChefSchedule: PropTypes.func,
  displayFailureMessage: PropTypes.func,
}

export default ScheduleEditModal
